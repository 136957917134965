import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useAuth } from "../../context/authProvider";
import SearchIcon from "@mui/icons-material/Search";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import MailIcon from "@mui/icons-material/Mail";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  AppBar,
  Toolbar,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  Container,
  Grid,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { exportToExcel, exportToPdf } from "../../utils/Helper";
import { IAppState } from "../../types/common";

const MyNavbar = ({ handleDrawerToggle, drawerWidth }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const { setToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isLTR: boolean = i18n.language === "en";
  const { t } = useTranslation();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = (): void => {
    setToken({
      accessToken: null,
      refreshToken: null,
    });
    navigate("/", { replace: true });
  };

  const dispatch = useDispatch();
  const searchQuery = useSelector((state: any) => state.searchQuery);
  let selectedBusinessIDs: string[] = useSelector(
    (state: IAppState) => state.selectedBusinessIDs
  );

  let businessList: any[] = useSelector(
    (state: IAppState) => state.businessList
  );

  if (businessList.length && selectedBusinessIDs.length) {
    businessList = businessList?.filter((item) =>
      selectedBusinessIDs.includes(item.businessId)
    );
  }

  const handleSearch = (event) => {
    const query = event.target.value;
    dispatch({ type: "SET_SEARCH_QUERY", payload: query });

    if (location.pathname !== "/") {
      navigate("/", { replace: true });
    }
  };

  const handleExportToExcel = () => {
    const selectedFieldsMapping = {
      "Business Name": "businessName",
      Address: "businessAddress",
      "Contact Name": "contactName",
      "Company Number": "companyNumber",
      "Company Name": "companyName",
      Phone: "businessPhoneNumber",
      Email: "email",
      "Account Status": "isActive",
      "Registration Date": "createdAt",
      "Deactivate Date": "deactivateDate",
      "Deactivate Comments": "deactivateComments",
      "Active Date": "activateDate",
      "Active Comments": "activateComments",
    };
    exportToExcel(businessList, selectedFieldsMapping);
  };

  const handleExportToPdf = () => {
    exportToPdf(businessList);
  };

  const buttonStyle = {
    color: "#FE0000",
    borderRadius: "20px",
    textTransform: "none",
    height: "40px",
    px: 2,
    fontSize: "14px",
    minWidth: "40px",
    "&:hover": {
      backgroundColor: "#e2dcdc",
    },
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {isMobile && (
        <MenuItem
          component={Link}
          to="/open-business"
          onClick={handleMenuClose}
        >
          <AddBusinessIcon sx={{ mr: 1, color: "#FE0000" }} />
          Open new business
        </MenuItem>
      )}
      <MenuItem onClick={handleExportToExcel}>
        <img
          src="/xlsx-24.png"
          width={20}
          alt="XLSX"
          style={{ marginRight: 8 }}
        />
        Export to Excel
      </MenuItem>
      <MenuItem onClick={handleExportToPdf}>
        <img
          src="/pdf_filetype_icon.png"
          width={20}
          alt="PDF"
          style={{ marginRight: 8 }}
        />
        Export to PDF
      </MenuItem>
      {isMobile && (
        <MenuItem onClick={handleSignOut}>
          <LogoutIcon sx={{ mr: 1, color: "#FE0000" }} />
          {t("lbl.logout")}
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: "#FE0000",
      }}
    >
      <Toolbar
        sx={{
          minHeight: { xs: 56, sm: 64 },
          px: { xs: 1, sm: 2 },
          py: 1,
          justifyContent: "space-evenly",
        }}
      >
        {/* {!isMobile && !isTablet && (
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { xs: "0.813rem", sm: "0.875rem" },
              color: "#FFFFFF",
              letterSpacing: "-0.025em",
              textTransform: "uppercase",
            }}
            noWrap
          >
            Hello Ron
          </Typography>
        )} */}

        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, ml: 1, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            borderRadius: "20px",
            width: { xs: "100%", sm: "100%", md: "40%" },
            height: "40px",
            mx: { xs: 1, sm: 0 },
          }}
        >
          <SearchIcon sx={{ color: "#000000", ml: 1.5 }} />
          <InputBase
            placeholder={t("lbl.search_placeholder")}
            value={searchQuery}
            onChange={handleSearch}
            sx={{
              ml: 1,
              flex: 1,
              color: "#000000",
              fontSize: { xs: "0.813rem", sm: "0.875rem" },
            }}
            inputProps={{ "aria-label": "search businesses" }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mx: { xs: 1, sm: 2 },
            ml: { xs: 1, sm: 2 },
            gap: 2,
          }}
        >
          {!isMobile && (
            <>
              <Tooltip title="Open a new business">
                <Button
                  component={Link}
                  to="/open-business"
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    backgroundColor: "#FFFFFF",
                    whiteSpace: "nowrap",
                    minWidth: isTablet ? "40px" : "auto",
                  }}
                  startIcon={!isTablet && <AddBusinessIcon />}
                >
                  {isTablet ? <AddBusinessIcon /> : "Open a new business"}
                </Button>
              </Tooltip>
              {/* <Tooltip title="Certified mail">
                <Button
                  component={Link}
                  to="/certified-mail"
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    backgroundColor: "#FFFFFF",
                    ml: 1,
                    minWidth: isTablet ? "40px" : "auto",
                  }}
                  startIcon={!isTablet && <MailIcon />}
                >
                  {isTablet ? <MailIcon /> : "Certified mail"}
                </Button>
              </Tooltip> */}
            </>
          )}
          <Tooltip title={isMobile ? "Open Menu" : "Export options"}>
            {isMobile ? (
              <IconButton
                onClick={handleMenuOpen}
                sx={{
                  color: "#FFFFFF",
                  ml: 1,
                }}
              >
                <MoreVertIcon />
              </IconButton>
            ) : (
              <>
                <Button
                  onClick={handleExportToPdf}
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    backgroundColor: "#FFFFFF",
                    whiteSpace: "nowrap",
                    minWidth: isTablet ? "40px" : "auto",
                  }}
                  startIcon={
                    !isTablet && (
                      <img
                        src="/pdf_filetype_icon.png"
                        width={20}
                        alt="PDF"
                        style={{ marginRight: 8 }}
                      />
                    )
                  }
                >
                  {isTablet ? (
                    <img
                      src="/pdf_filetype_icon.png"
                      width={20}
                      alt="PDF"
                      style={{ marginRight: 8 }}
                    />
                  ) : (
                    "Export to PDF"
                  )}
                </Button>
                <Button
                  onClick={handleExportToExcel}
                  variant="contained"
                  sx={{
                    ...buttonStyle,
                    backgroundColor: "#FFFFFF",
                    whiteSpace: "nowrap",
                    minWidth: isTablet ? "40px" : "auto",
                  }}
                  startIcon={
                    !isTablet && (
                      <img
                        src="/xlsx-24.png"
                        width={20}
                        alt="PDF"
                        style={{ marginRight: 8 }}
                      />
                    )
                  }
                >
                  {isTablet ? (
                    <img
                      src="/xlsx-24.png"
                      width={20}
                      alt="PDF"
                      style={{ marginRight: 8 }}
                    />
                  ) : (
                    "Export to Excel"
                  )}
                </Button>
              </>
            )}
          </Tooltip>
        </Box>

        {!isMobile && (
          <Box>
            <Tooltip title="Logout">
              <Button
                variant="contained"
                sx={{
                  ...buttonStyle,
                  whiteSpace: "nowrap",
                  backgroundColor: "#FFFFFF",
                  ml: 1,
                  minWidth: isTablet ? "40px" : "auto",
                }}
                startIcon={!isTablet && <LogoutIcon />}
                onClick={handleSignOut}
              >
                {isTablet ? <LogoutIcon /> : t("lbl.logout")}
              </Button>
            </Tooltip>
          </Box>
        )}
      </Toolbar>
      {renderMenu}
    </AppBar>
  );
};

export default MyNavbar;
