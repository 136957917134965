import client from "./client";

const BACKEND_API_PREFIX = process.env.REACT_APP_API_PREFIX;

client.defaults.baseURL = BACKEND_API_PREFIX;

const searchLocations = (query: string) => {
  return client.get(`${process.env.REACT_APP_LOCATION_SEARCH}?query=${query}`);
};

const getPlaceDetails = (placeId: string) => {
  return client.get(`${process.env.REACT_APP_PLACES}?placeId=${placeId}`);
};

export default {
  searchLocations,
  getPlaceDetails,
};
