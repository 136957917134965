import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter } from "../utils/Helper";
import { BsPlus } from "react-icons/bs";
import AddIcon from "@mui/icons-material/Add";
import useApi from "../hooks/useApi";
import couponApi from "../api/coupon";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSnackbar } from "../context/snackbarContext";
const { format } = require("date-fns");

import CouponModal from "./modals/CouponModal";
import { CouponResponseDto, ICreateCouponDto } from "../types/coupon";
import CouponDeactivateConfirmation from "./modals/CouponDeactivateConfirmation";

const Coupons = () => {
  const { t } = useTranslation();

  const [couponsList, setCouponsList] = useState<CouponResponseDto[]>([]);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] =
    useState<boolean>(false);
  const [selectedCoupon, setSelectedCoupon] = useState<
    CouponResponseDto | undefined
  >(undefined);

  const toggleCouponModal = () => {
    setShowCouponModal(!showCouponModal);
  };

  const toggleDeactivateModal = () => {
    setShowDeactivateModal(!showDeactivateModal);
  };

  const createCouponApi = useApi(couponApi.createCoupon);
  const getCouponApi = useApi(couponApi.getAllCoupons);
  const updateCouponApi = useApi(couponApi.updateCoupon);
  const deactivateCouponApi = useApi(couponApi.deactivateCoupon);

  const { showSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: capitalizeFirstLetter("name"),
      width: 150,
    },
    {
      field: "code",
      headerName: capitalizeFirstLetter("code"),
      width: 150,
    },
    {
      field: "discountType",
      headerName: capitalizeFirstLetter("discountType"),
      width: 150,
    },
    {
      field: "value",
      headerName: capitalizeFirstLetter("value"),
      width: 150,
    },
    {
      field: "isActive",
      headerName: capitalizeFirstLetter("isActive"),
      valueGetter: (params) => {
        return params.value === true ? "Active" : "Inactive";
      },
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      valueGetter: (params) => {
        const formattedDate = format(new Date(params.value), "dd/MMM/yyyy");
        return formattedDate;
      },
      resizable: true,
      width: 150,
    },
    {
      field: "couponId",
      headerName: capitalizeFirstLetter("Action"),
      renderCell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            <Link
              to={"#"}
              className="me-2 text-danger"
              onClick={() => {
                setSelectedCoupon(row);
                toggleCouponModal();
              }}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </Link>
            <Link
              to={"#"}
              className="me-2 text-danger"
              onClick={() => {
                setSelectedCoupon(row);
                toggleDeactivateModal();
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Link>
          </div>
        );
      },
      width: 150,
    },
  ];

  useEffect(() => {
    getCouponApi.request("");
  }, []);

  useEffect(() => {
    let couponApiResponse: any = getCouponApi?.data;
    if (couponApiResponse) {
      if (couponApiResponse?.data && couponApiResponse?.data?.length > 0) {
        setCouponsList(couponApiResponse?.data);
      } else {
        showSnackbar(couponApiResponse?.message || "No data found");
      }
    }
  }, [getCouponApi?.data]);

  useEffect(() => {
    if (!showCouponModal && !showDeactivateModal) {
      setSelectedCoupon(undefined);
    }
  }, [showCouponModal, showDeactivateModal]);

  const handleSaveCoupon = async (payload: ICreateCouponDto) => {
    try {
      if (selectedCoupon) {
        await updateCouponApi.request(selectedCoupon?.couponId, payload);
      } else {
        await createCouponApi.request(payload);
      }

      await getCouponApi.request(""); // Refresh table data
      showSnackbar("Coupon saved successfully!", "success");
    } catch (error) {
      showSnackbar(error || "Failed to save coupon", "error");
    } finally {
      setSelectedCoupon(undefined);
      toggleCouponModal(); // Close modal
    }
  };

  const handleDeactivateCoupon = async () => {
    try {
      if (selectedCoupon) {
        await deactivateCouponApi.request(selectedCoupon?.couponId);
        await getCouponApi.request(""); // Refresh table data
        showSnackbar("Coupon deactivated successfully!", "success");
      }
    } catch (error) {
      showSnackbar(error || "Failed to deactivate coupon", "error");
    } finally {
      setSelectedCoupon(undefined);
      toggleDeactivateModal(); // Close modal
    }
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{
          mb: 2,
          borderRadius: "50px",
          backgroundColor: "#FE0000",
          color: "#FFFFFF",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "#d30202",
          },
        }}
        onClick={() => {
          toggleCouponModal();
        }}
        startIcon={<AddIcon style={{ color: "white" }} />}
      >
        Add new coupon
      </Button>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <DataGrid
          rows={couponsList}
          loading={getCouponApi.loading}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          getRowId={(row) => row.couponId}
          sx={{
            width: {
              xs: "200px", // width for small screens
              sm: "400px", // width for small-medium screens
              md: "700px", // width for medium screens
              lg: "1000px", // width for large screens
              xl: "1300px", // width for extra-large screens
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-footerContainer": {
              justifyContent: "center",
            },
            "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                margin: "0",
              },
            "--DataGrid-overlayHeight": "520px",
            "& .Mui-checked, & .MuiCheckbox-indeterminate": {
              color: "#fe0000 !important",
            },
          }}
          disableColumnMenu
          autoHeight
        />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <CouponDeactivateConfirmation
        isModalVisible={showDeactivateModal}
        setIsModalVisible={setShowDeactivateModal}
        handleConfirmAction={handleDeactivateCoupon}
      />

      <CouponModal
        showModal={showCouponModal}
        toggleModal={toggleCouponModal}
        couponDetails={selectedCoupon}
        handleSaveCoupon={handleSaveCoupon}
      />
    </>
  );
};

export default Coupons;
