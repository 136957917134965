import {
  RouterProvider,
  createBrowserRouter,
  RouteObject,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { useAuth } from "../context/authProvider";
import { ProtectedRoute } from "./ProtectedRoute";
import Login from "../components/Login";
import Signup from "../components/Signup";
import Home from "../components/Home";
import Registration from "../components/Registration";
import MainLayout from "../components/MainLayout";
import Page404 from "../components/Page404";
import OpenNewBusiness from "../components/OpenNewBusiness";
import BusinessProfile from "../components/BusinessProfile";
import CertifiedMail from "../components/CertifiedMail";
import Business from "../components/Business";
import Revenue from "../components/Revenue";
import Expense from "../components/Expense";
import Customer from "../components/Customer";
import Complaints from "../components/Complaints";
import Coupons from "../components/Coupons";

const AppRoute = () => {
  const { token } = useAuth();

  // public routes accessible to all users
  const routesForPublic: RouteObject[] = [
    {
      path: "/contact",
      element: (
        <MainLayout>
          <div>Contact Page</div>
        </MainLayout>
      ),
    },
    {
      path: "*",
      element: <Page404 />,
    },
  ];

  // routes accessible only to authenticated users
  const routesForAuthenticatedOnly: RouteObject[] = [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "",
          element: (
            <MainLayout>
              <Home />
            </MainLayout>
          ),
        },
        {
          path: "/home",
          element: (
            <MainLayout>
              <Home />
            </MainLayout>
          ),
        },
        {
          path: "/complaints",
          element: (
            <MainLayout>
              <Complaints />
            </MainLayout>
          ),
        },
        {
          path: "/open-business/:id?",
          element: (
            <MainLayout>
              <OpenNewBusiness />
            </MainLayout>
          ),
        },
        {
          path: "/revenue",
          element: (
            <MainLayout>
              <Revenue />
            </MainLayout>
          ),
        },
        {
          path: "/expense",
          element: (
            <MainLayout>
              <Expense />
            </MainLayout>
          ),
        },
        {
          path: "/business",
          element: (
            <MainLayout>
              <Business />
            </MainLayout>
          ),
        },
        {
          path: "/business-profile/:id",
          element: <BusinessProfile />,
        },
        {
          path: "/customer",
          element: (
            <MainLayout>
              <Customer />
            </MainLayout>
          ),
        },
        {
          path: "/coupons",
          element: (
            <MainLayout>
              <Coupons />
            </MainLayout>
          ),
        },
        {
          path: "/certified-mail",
          element: (
            <MainLayout>
              <CertifiedMail />
            </MainLayout>
          ),
        },
      ],
    },
  ];

  // routes accessible only to non-authenticated users
  const routesForNotAuthenticatedOnly: RouteObject[] = [
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    // {
    //   path: "/signup",
    //   element: <Signup />,
    // },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...(token.accessToken === null || token.accessToken === "null"
      ? routesForNotAuthenticatedOnly
      : []),
    ...routesForAuthenticatedOnly,
  ]);

  return <RouterProvider router={router} />;
};

export default AppRoute;
