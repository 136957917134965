import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Button,
} from "@mui/material";
const { jsPDF } = require("jspdf");
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import CloseIcon from "@mui/icons-material/Close";

interface DetailsModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  data: any[];
  loading: boolean;
  error: string;
  fromDate: string;
  toDate: string;
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  open,
  handleClose,
  title,
  data,
  loading,
  error,
  fromDate,
  toDate,
}) => {
  // Define column configurations for different titles
  const columns: Record<string, GridColDef[]> = {
    "Active Businesses": [
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 200 },
      { field: "businessPhoneNumber", headerName: "Phone Number", width: 150 },
      { field: "email", headerName: "Email", width: 180 },
      { field: "city", headerName: "City", width: 120 },
      { field: "companyNumber", headerName: "Company Number", width: 150 },
      { field: "companyName", headerName: "Company Name", width: 150 },
      { field: "isKosher", headerName: "Is Kosher", width: 100 },
      { field: "foodType", headerName: "Food Type", width: 180 },
      { field: "businessType", headerName: "Business Type", width: 180 },
    ],
    "Removed Businesses": [
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 200 },
      { field: "email", headerName: "Email Address", width: 200 },
      { field: "businessPhoneNumber", headerName: "Phone Number", width: 200 },
      { field: "deactivateDate", headerName: "Date Removed", width: 150 },
    ],
    "Businesses Joined By Date": [
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 250 },
      { field: "createdAt", headerName: "Joining Date", width: 150 },
    ],
    "Amount Of Benefits Raised": [
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "benefitsRaised", headerName: "Amount Raised", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 250 },
    ],
  };

  const handleExportPDF = () => {
    const doc = new jsPDF("l"); // Landscape mode
    doc.text(`${title}_${fromDate} - ${toDate}`, 20, 10);
    const columnHeaders = columns[title]?.map((col) => col.headerName) || [];
    const rows = data.map(
      (row) => columns[title]?.map((col) => row[col.field]) || []
    );

    doc.autoTable({
      head: [columnHeaders],
      body: rows,
      startY: 20,
      tableWidth: "auto", // Fits table to page width
      styles: {
        cellPadding: 3,
        fontSize: 7, // Reduced font size for better fit
        overflow: "linebreak",
      },
      headStyles: {
        fillColor: [41, 128, 185], // Custom header background color
        textColor: 255, // White text
      },
      didDrawPage: (data) => {
        // Header
        doc.setFontSize(14);
        doc.setTextColor(40);
        // doc.text("Registered Business List", data.settings.margin.left, 15);

        // Footer
        const pageCount = doc.internal.getNumberOfPages();
        const pageHeight = doc.internal.pageSize.height;
        doc.setFontSize(10);
        doc.text(
          `Page ${
            doc.internal.getCurrentPageInfo().pageNumber
          } of ${pageCount}`,
          data.settings.margin.left,
          pageHeight - 10
        );
      },
    });
    doc.save(`${title}_${fromDate} - ${toDate}.pdf`);
  };

  const handleExportExcel = () => {
    // Extract displayed column fields and their header names
    const displayedColumns =
      columns[title]?.map((col) => ({
        field: col.field,
        headerName: col.headerName,
      })) || [];

    // Filter data to include only the displayed columns
    const filteredData = data.map((row) => {
      const filteredRow = {};
      displayedColumns.forEach(({ field }) => {
        // Convert arrays to comma-separated strings for Excel compatibility
        if (Array.isArray(row[field])) {
          filteredRow[field] = row[field].join(", ");
        } else {
          // Safely retrieve the value or default to an empty string
          filteredRow[field] = row[field] !== undefined ? row[field] : "";
        }
      });
      return filteredRow;
    });

    // Create worksheet with filtered data
    const worksheet = XLSX.utils.json_to_sheet(filteredData);

    // Set column headers based on displayed columns
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [displayedColumns.map((col) => col.headerName)],
      { origin: "A1" }
    );

    // Create workbook and save file
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, title);
    XLSX.writeFile(workbook, `${title}_${fromDate} - ${toDate}.xlsx`);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <DialogTitle>{title}</DialogTitle>

        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 3,
          gap: 2,
        }}
      >
        <IconButton onClick={handleExportExcel}>
          <img src="./xlsx-24.png" width={20} alt="XLSX" />
        </IconButton>
        <IconButton onClick={handleExportPDF}>
          <img src="./pdf_filetype_icon.png" width={20} alt="XLSX" />
        </IconButton>
      </Box>

      <DialogContent>
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}
        {data && (
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data.map((row) => ({
                id: row._id,
                ...row,
              }))}
              columns={columns[title] || []}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsModal;
