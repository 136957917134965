import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { DateCalendar } from "@mui/x-date-pickers-pro";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";

const OneTimeTab = ({
  handleSubmitPromotion,
  description,
}: {
  handleSubmitPromotion: Function;
  description: string;
}) => {
  const [isAllDay, setIsAllDay] = useState(false);
  const [fromTime, setFromTime] = useState<Dayjs | null>(null);
  const [toTime, setToTime] = useState<Dayjs | null>(null);
  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedOptions, setSelectedOptions] = useState({
    takeAway: false,
    dineIn: false,
    reservesDiscount: false,
  });

  const isButtonDisabled =
    !description || // Parent description is empty
    (!isAllDay && (!fromTime || !toTime)) || // Timing is incomplete
    !selectedDate; // No days are selected

  // Handlers
  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(event.target.checked);
    if (event.target.checked) {
      setFromTime(dayjs().startOf("day")); // 12:00 AM
      setToTime(dayjs().endOf("day")); // 11:59 PM
    } else {
      setFromTime(null);
      setToTime(null);
    }
  };

  const handleOptionChange = (option: string) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  const handleFromTimeChange = (time: Dayjs | null) => {
    setFromTime(time);
  };

  const handleToTimeChange = (time: Dayjs | null) => {
    setToTime(time);
  };

  const handleSubmit = async () => {
    const formattedDate = selectedDate
      ? dayjs(selectedDate).format("YYYY-MM-DD")
      : null;
    // Prepare the payload for Ongoing promotion
    const payload = {
      scheduleType: "oneTime",
      isTakeAway: selectedOptions.takeAway,
      isDineIn: selectedOptions.dineIn,
      isMiluim: selectedOptions.reservesDiscount,
      startDate: formattedDate,
      endDate: formattedDate,
      startTime: fromTime ? fromTime.format("hh:mm A") : null,
      endTime: toTime ? toTime.format("hh:mm A") : null,
    };

    // Call the shared handleSubmitPromotion function
    await handleSubmitPromotion(payload);
  };

  return (
    <Box
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        padding: "24px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        margin: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Title */}
      <Typography variant="h5" color="error" sx={{ mb: 2, fontWeight: "bold" }}>
        OnGoing
      </Typography>

      {/* Checkboxes */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="error"
                checked={selectedOptions.takeAway}
                onChange={() => handleOptionChange("takeAway")}
              />
            }
            label="Take Away"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="error"
                checked={selectedOptions.dineIn}
                onChange={() => handleOptionChange("dineIn")}
              />
            }
            label="Dine In"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="error"
                checked={selectedOptions.reservesDiscount}
                onChange={() => handleOptionChange("reservesDiscount")}
              />
            }
            label="Reserves and Soldiers Discount"
          />
        </Grid>
      </Grid>

      {/* Date Range Picker */}
      <Box sx={{ mt: 5 }}>
        <DateCalendar
          value={selectedDate}
          onChange={(newDate) => setSelectedDate(newDate)}
        />
      </Box>

      {/* All Day Checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            color="error"
            checked={isAllDay}
            onChange={handleAllDayChange}
          />
        }
        label="All Day (12:00 AM - 11:59 PM)"
      />

      {/* Time Selection */}
      {!isAllDay && (
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="From Time"
              value={fromTime}
              onChange={handleFromTimeChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="To Time"
              value={toTime}
              onChange={handleToTimeChange}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      )}

      {/* Submit Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#FE0000",
          color: "#ffffff",
          width: "100%",
          fontWeight: "bold",
          "&:hover": { backgroundColor: "#bf0f1b" },
        }}
        disabled={isButtonDisabled}
        onClick={handleSubmit}
      >
        Advertise a Benefit
      </Button>
    </Box>
  );
};

export default OneTimeTab;
