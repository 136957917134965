import React, { useState } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Grid,
  Button,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";

const BusinessLunchTab = ({
  handleSubmitPromotion,
  description,
}: {
  handleSubmitPromotion: Function;
  description: string;
}) => {
  const [isAllDay, setIsAllDay] = useState(false);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [fromTime, setFromTime] = useState<Dayjs | null>(null);
  const [toTime, setToTime] = useState<Dayjs | null>(null);

  // Weekday groups
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const sundayToWednesday = weekDays.slice(0, 4);
  const sundayToThursday = weekDays.slice(0, 5);

  const isButtonDisabled =
    !description || // Parent description is empty
    (!isAllDay && (!fromTime || !toTime)) || // Timing is incomplete
    selectedDays.length === 0; // No days are selected

  // Handlers
  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(event.target.checked);
    if (event.target.checked) {
      setFromTime(dayjs().startOf("day")); // 12:00 AM
      setToTime(dayjs().endOf("day")); // 11:59 PM
    } else {
      setFromTime(null);
      setToTime(null);
    }
  };

  const handleTimingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "All Week") {
      setSelectedDays(weekDays);
    } else if (value === "Sunday to Wednesday") {
      setSelectedDays(sundayToWednesday);
    } else if (value === "Sunday to Thursday") {
      setSelectedDays(sundayToThursday);
    } else {
      setSelectedDays([]);
    }
  };

  const handleFromTimeChange = (time: Dayjs | null) => {
    setFromTime(time);
  };

  const handleToTimeChange = (time: Dayjs | null) => {
    setToTime(time);
  };

  const handleSubmit = async () => {
    // Prepare the payload for Business Lunch
    const payload = {
      scheduleType: "businessMeal", // Specific to Business Lunch
      startDate: "",
      weekdays: selectedDays,
      startTime: fromTime ? fromTime.format("hh:mm A") : null,
      endDate: "",
      endTime: toTime ? toTime.format("hh:mm A") : null,
      isTakeAway: false,
      isDineIn: false,
      isMiluim: false,
    };

    // Call the shared handleSubmitPromotion function
    await handleSubmitPromotion(payload);
  };

  return (
    <Box
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        padding: "24px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        margin: "auto",
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h5" color="error" sx={{ mb: 2, fontWeight: "bold" }}>
        Business Lunch
      </Typography>

      {/* All Day Checkbox */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography variant="h6">Timing Selection:</Typography>
        <FormControlLabel
          control={
            <Checkbox
              color="error"
              checked={isAllDay}
              onChange={handleAllDayChange}
            />
          }
          label="All Day (12:00 AM - 11:59 PM)"
        />
      </Box>

      {/* Timing Radio Buttons */}
      <RadioGroup onChange={handleTimingChange} sx={{ mb: 2 }}>
        <FormControlLabel
          value="All Week"
          control={<Radio color="error" />}
          label="All Week"
        />
        <FormControlLabel
          value="Sunday to Wednesday"
          control={<Radio color="error" />}
          label="Sunday to Wednesday"
        />
        <FormControlLabel
          value="Sunday to Thursday"
          control={<Radio color="error" />}
          label="Sunday to Thursday"
        />
      </RadioGroup>

      {/* Time Selection */}
      {!isAllDay && (
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="From Time"
              value={fromTime}
              onChange={handleFromTimeChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="To Time"
              value={toTime}
              onChange={handleToTimeChange}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      )}

      {/* Submit Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#FE0000",
          color: "#ffffff",
          width: "100%",
          fontWeight: "bold",
          "&:hover": { backgroundColor: "#bf0f1b" },
        }}
        disabled={isButtonDisabled}
        onClick={handleSubmit}
      >
        Advertise a Benefit
      </Button>
    </Box>
  );
};

export default BusinessLunchTab;
