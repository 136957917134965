import { useTranslation } from "react-i18next";
import {
  AiOutlineAlignRight,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineFontSize,
} from "react-icons/ai";
import { FaMusic, FaTrash } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";
import PhotoIcon from "@mui/icons-material/Photo";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import MyFloatingButtons from "../inc/MyFloatingButtons";
import { useEffect, useRef, useState } from "react";
import { ICreatePromotionDto } from "../../types/promotion";
import Webcam from "react-webcam";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Button as MUIButton,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextareaAutosize,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TfiGallery } from "react-icons/tfi";
import ReplayIcon from "@mui/icons-material/Replay";
import { styled, useTheme } from "@mui/material/styles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import promotionApi from "../../api/promotion";
import useApi from "../../hooks/useApi";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DatePicker } from "react-bootstrap-date-picker";

import "bootstrap-daterangepicker/daterangepicker.css";
import { v4 as uuidv4 } from "uuid";
import useStorage from "../../hooks/useStorage";
import { IResponseModel } from "../../types/common";
import { useSnackbar } from "../../context/snackbarContext";
import PictureFromDatabase from "../PictureFromDatabase";
import {
  DateCalendar,
  LocalizationProvider,
  pickersLayoutClasses,
  renderTimeViewClock,
  StaticTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import BusinessLunchTab from "../businessLunchTab";
import HappyHourTab from "../happyHourTab";
import OnGoingTab from "../onGoingTab";
import OneTimeTab from "../oneTimeTab";

const AdvertiseABusiness = ({
  showAdvertiseABusinessModal,
  toggleAdertiseBusinessModal,
  businessDetails,
  getPromotionsApi,
  selectedPromotion,
  setSelectedPromotion,
}) => {
  const { t } = useTranslation();

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<ICreatePromotionDto>();

  const { showSnackbar } = useSnackbar();

  const webcamRef = useRef<any>(null);
  const fileInputRef = useRef<any>(null);
  const [imageSource, setImageSource] = useState<any>(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<any>(null);
  const [isImageSelected, setIsImageSelected] = useState<boolean>(false);
  const [isDBPicturesVisible, setIsDBPicturesVisible] =
    useState<boolean>(false);
  const [isImageEdited, setIsImageEdited] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState(0); // State to track selected tab

  const [startDate, setStartDate] = useState<any>(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const [isAllDay, setIsAllDay] = useState(false);
  const createPromotionApi = useApi(promotionApi.createPromotion);
  const updatePromotionApi = useApi(promotionApi.updatePromotion);
  const s3StorageService = useStorage();

  const handleAllDayChange = () => {
    setIsAllDay((prev) => !prev);
  };

  const determineMediaType = (file: File): string | null => {
    const allowedExtensions = {
      "1": ["jpg", "jpeg", "png", "gif"], // Image extensions
      "2": ["mp4", "mov", "avi", "mkv"], // Video extensions
    };

    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!fileExtension) return null;

    if (allowedExtensions["1"].includes(fileExtension)) return "1"; // Image
    if (allowedExtensions["2"].includes(fileExtension)) return "2"; // Video

    return null; // Unsupported type
  };

  const determineMediaTypeFromUrl = (url: string): string | null => {
    const allowedExtensions = {
      "1": ["jpg", "jpeg", "png", "gif"], // Image extensions
      "2": ["mp4", "mov", "avi", "mkv"], // Video extensions
    };

    const fileExtension = url.split(".").pop()?.toLowerCase();
    if (!fileExtension) return null;

    if (allowedExtensions["1"].includes(fileExtension)) return "1"; // Image
    if (allowedExtensions["2"].includes(fileExtension)) return "2"; // Video

    return null; // Unsupported type
  };

  const handleSubmitPromotion = async (payload: ICreatePromotionDto) => {
    let fileObj = imageSource;
    if (fileObj instanceof File) {
      const mediaType = determineMediaType(fileObj);
      if (!mediaType) {
        console.error("Invalid file type for promotion media.");
        return;
      }

      let phoneNumber = businessDetails.businessPhoneNumber.replace(/\+/g, "");
      const fileUploadResponse = await s3StorageService.handleFileUpload(
        fileObj,
        mediaType,
        `business/${phoneNumber}/businessMedia`
      );

      if (fileUploadResponse.success) {
        payload.mediaUrl = fileUploadResponse.url; // Use `url` instead of `location`
        payload.mediaType = mediaType;
      } else {
        console.error("File upload failed:", fileUploadResponse.error);
      }
    } else if (!imageSource && selectedImageUrl) {
      payload.mediaUrl = selectedImageUrl;
      payload.mediaType = determineMediaTypeFromUrl(selectedImageUrl) ?? "1"; // Default to image
    }
    payload.businessId = businessDetails.businessId;
    const { promotion, isReupload } = selectedPromotion ?? {};
    if (promotion && !isReupload) {
      const response = await updatePromotionApi.request(
        promotion.promotionId,
        payload
      );
    } else {
      const response = await createPromotionApi.request(payload);
    }
  };

  useEffect(() => {
    let response: IResponseModel =
      createPromotionApi?.data ?? updatePromotionApi?.data;
    if (response) {
      if (response?.isSuccess) {
        showSnackbar(response?.message);
        getPromotionsApi.request(businessDetails.businessId);
        setImageSource(null);
        setSelectedImageUrl(null);
        setIsImageSelected(false);
        setIsDBPicturesVisible(false);
        setSelectedPromotion(undefined);
        toggleAdertiseBusinessModal(false);
      } else {
        showSnackbar(response?.message || "Something went wrong");
      }
    }
  }, [createPromotionApi?.data, updatePromotionApi?.data]);

  useEffect(() => {
    if (selectedPromotion) {
      const { promotion } = selectedPromotion;
      const entries = Object.entries(promotion);
      entries.forEach(([key, value]: [any, any]) => {
        setValue(key, value);
      });
      setSelectedImageUrl(promotion.mediaUrl);
      setIsImageSelected(true);
    }
  }, [selectedPromotion]);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); // Update selected tab
  };
  const capture = async () => {
    const webCamImageSrc = webcamRef?.current?.getScreenshot();

    if (!webCamImageSrc) {
      // No camera or unable to capture an image
      showSnackbar("No camera or unable to capture an image");
      setImageSource(null); // This ensures no file is set
      setSelectedImageUrl(null); // Handle null state for display
      return;
    }

    const blob = await fetch(webCamImageSrc).then((res) => res.blob());
    const webCamImagefile = new File([blob], "captured-image.png", {
      type: "image/png",
    });

    setImageSource(webCamImagefile);
    setSelectedImageUrl(webCamImageSrc);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageSource(file);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImageUrl(imageUrl);
    }
  };

  const handleFileSelect = () => {
    fileInputRef?.current?.click();
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : "50%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    overflow: "hidden",
  };

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    bgcolor: "black",
    color: "white",
    padding: theme.spacing(1, 2),
  };

  const footerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    bgcolor: "black",
    color: "white",
    padding: theme.spacing(1, 2),
  };

  const bodyStyle = {
    bgcolor: "grey.900",
    height: "300px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    position: "relative", // To handle the absolute positioning inside
  };
  const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "contain", // Ensures the image covers the Box without distortion
    borderRadius: "8px", // Optional, for rounded corners
  };
  const CustomTextareaAutosize: React.CSSProperties = {
    width: "100%",
    padding: "10px",
    borderRadius: "15px",
    border: "2px solid #ffffff",
    backgroundColor: "transparent",
    color: "#fff",
    fontSize: "20px",
    resize: "none",
    boxSizing: "border-box",
  };
  const customTextAreaPlaceholderStyle = `
  .custom-placeholder::placeholder {
    color: white;
  }
`;
  return (
    <Modal
      open={showAdvertiseABusinessModal}
      onClose={() => {
        setSelectedTab(0);
        reset({ description: "" });
        setImageSource(null);
        setSelectedImageUrl(null);
        setIsImageSelected(false);
        setIsDBPicturesVisible(false);
        setSelectedPromotion(undefined);
        toggleAdertiseBusinessModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {isImageSelected ? (
          <Box
            sx={{
              backgroundImage: `url(${selectedImageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "80vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              position: "relative",
              overflow: "auto",
            }}
          >
            {/* Overlay to fade background image */}
            <Box
              sx={{
                position: "fixed", // Fixed to always cover the viewport area
                top: 0,
                left: 0,
                width: "100%",
                height: "100%", // Cover full height regardless of scroll
                backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black overlay
                zIndex: 0, // Ensures the overlay is on top of the background image
                pointerEvents: "none", // Allows interaction with content below
              }}
            />
            <Box sx={{ position: "relative", width: "100%", zIndex: 1 }}>
              <Box
                sx={{
                  padding: "10px",
                }}
              >
                <IconButton
                  onClick={() => {
                    setSelectedTab(0);
                    reset({ description: "" });
                    setImageSource(null);
                    setSelectedImageUrl(null);
                    setIsImageSelected(false);
                    setIsDBPicturesVisible(false);
                    setSelectedPromotion(undefined);
                    toggleAdertiseBusinessModal(false);
                  }}
                  sx={{ color: "white" }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box sx={{ width: "100%", padding: "20px" }}>
                <form onSubmit={handleSubmit(handleSubmitPromotion)}>
                  <FormControl sx={{ width: "100%" }}>
                    <Controller
                      name="description"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: t("lbl.benefit_description_required"),
                      }}
                      render={({ field }) => {
                        return (
                          <div>
                            <TextareaAutosize
                              {...field}
                              minRows={3}
                              maxRows={6}
                              placeholder="What is the offer/benefit? For example 20% discount, 1+1 on starters...."
                              style={CustomTextareaAutosize}
                              className="custom-placeholder"
                            />
                            <style>{customTextAreaPlaceholderStyle}</style>
                          </div>
                        );
                      }}
                    />
                    {errors.description && (
                      <p role="alert" style={{ color: "red", fontWeight: 500 }}>
                        *{errors.description.message}
                      </p>
                    )}
                  </FormControl>
                  {/* Tabs */}
                  <Box sx={{ mt: 5 }}>
                    <Tabs
                      value={selectedTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="Promotion Tabs"
                      sx={{
                        "& .MuiTab-root": {
                          color: "white", // Default tab color
                          fontWeight: "normal", // Normal font weight
                          textTransform: "capitalize",
                        },
                        "& .Mui-selected": {
                          color: "#FE0000 !important", // Active tab color
                          fontWeight: "bold", // Active tab font weight
                        },
                        "& .MuiTabs-indicator": {
                          backgroundColor: "#fe0000",
                        },
                      }}
                    >
                      <Tab label="Business Lunch" />
                      <Tab label="Happy Hour" />
                      <Tab label="Ongoing" />
                      <Tab label="One Time" />
                    </Tabs>

                    {/* Tab Content */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box sx={{ marginTop: "20px" }}>
                        {selectedTab === 0 && (
                          <BusinessLunchTab
                            description={watch("description")}
                            handleSubmitPromotion={(payload: any) => {
                              // Add description from the form state
                              const fullPayload = {
                                ...payload,
                                description: watch("description"),
                              };
                              handleSubmitPromotion(fullPayload);
                            }}
                          />
                        )}
                        {selectedTab === 1 && (
                          <HappyHourTab
                            description={watch("description")}
                            handleSubmitPromotion={(payload: any) => {
                              const fullPayload = {
                                ...payload,
                                description: watch("description"),
                              };
                              handleSubmitPromotion(fullPayload);
                            }}
                          />
                        )}
                        {selectedTab === 2 && (
                          <OnGoingTab
                            description={watch("description")}
                            handleSubmitPromotion={(payload: any) => {
                              const fullPayload = {
                                ...payload,
                                description: watch("description"),
                              };
                              handleSubmitPromotion(fullPayload);
                            }}
                          />
                        )}
                        {selectedTab === 3 && (
                          <OneTimeTab
                            description={watch("description")}
                            handleSubmitPromotion={(payload: any) => {
                              const fullPayload = {
                                ...payload,
                                description: watch("description"),
                              };
                              handleSubmitPromotion(fullPayload);
                            }}
                          />
                        )}
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        ) : (
          <>
            {/* Header */}
            <Box sx={headerStyle}>
              <IconButton
                onClick={() => {
                  setImageSource(null);
                  setSelectedImageUrl(null);
                  setIsImageSelected(false);
                  setIsDBPicturesVisible(false);
                  setSelectedPromotion(undefined);
                  toggleAdertiseBusinessModal(false);
                }}
                sx={{ color: "white" }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" component="div" align="center">
                {isDBPicturesVisible ? "Database Pictures" : "Upload Benefit"}
              </Typography>
              {!isDBPicturesVisible && (
                <Button
                  onClick={() => {
                    toggleAdertiseBusinessModal(false);
                  }}
                  variant="outlined"
                  sx={{
                    color: "white",
                    borderColor: "white",
                    textTransform: "capitalize",
                  }}
                >
                  To Business Profile
                </Button>
              )}
            </Box>

            {/* Body */}
            <Box sx={bodyStyle}>
              {isDBPicturesVisible ? (
                <div
                  style={{ width: "100%", height: "100%", overflowY: "scroll" }}
                >
                  <PictureFromDatabase
                    setSelectedImageUrl={setSelectedImageUrl}
                  />
                </div>
              ) : (
                <>
                  {imageSource === null ? (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover" as "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={selectedImageUrl}
                      alt="Selected"
                      style={imageStyle}
                    />
                  )}
                </>
              )}
            </Box>

            {/* Footer */}
            <Box sx={footerStyle}>
              {isDBPicturesVisible ? (
                <>
                  {/* Back Button */}
                  <Button
                    variant="text"
                    sx={{ color: "white", textTransform: "capitalize" }}
                    onClick={() => {
                      setSelectedImageUrl(null); // Clear selected image
                      setIsDBPicturesVisible(false); // Go back
                    }}
                  >
                    Back
                  </Button>

                  {/* Continue Button */}
                  {selectedImageUrl && (
                    <Button
                      variant="text"
                      sx={{ color: "white", textTransform: "capitalize" }}
                      onClick={() => {
                        setIsImageSelected(true); // Proceed with selected image
                        setIsDBPicturesVisible(false);
                      }}
                    >
                      Continue
                    </Button>
                  )}
                </>
              ) : (
                <>
                  {!imageSource ? (
                    <>
                      {/* File Input for Local Upload */}
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <IconButton
                        sx={{ color: "white" }}
                        onClick={handleFileSelect}
                      >
                        <PhotoIcon />
                      </IconButton>

                      {/* Capture Button */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <IconButton
                          onClick={() => capture()}
                          sx={{
                            bgcolor: "white",
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            border: "2px solid black",
                            "&:hover": {
                              bgcolor: "#FE0000", // Changes hover color
                            },
                          }}
                        />
                      </Box>

                      {/* Button to Show Database Pictures */}
                      <Button
                        onClick={() => {
                          setIsDBPicturesVisible(true);
                        }}
                        variant="text"
                        sx={{ color: "white", textTransform: "capitalize" }}
                      >
                        Picture from the database
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* Replay Button */}
                      <IconButton
                        aria-label="replay"
                        onClick={() => setImageSource(null)}
                      >
                        <ReplayIcon sx={{ color: "white" }} />
                      </IconButton>

                      {/* Continue Button */}
                      <Button
                        variant="outlined"
                        style={{ borderRadius: 100, color: "#FFFFFF" }}
                        onClick={() => setIsImageSelected(true)}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  showAdvertiseABusinessModal: state.showAdvertiseABusinessModal,
});

const dispatchStateToProps = (dispatch) => ({
  toggleAdertiseBusinessModal: (flag) => {
    dispatch({
      type: "TOGGLE_ADVERTISE_A_BUSINESS_MODAL",
      payload: flag,
    });
  },
});

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(AdvertiseABusiness);
