import client from "./client";
import { ICreateCouponDto } from "../types/coupon";

const BACKEND_API_PREFIX = process.env.REACT_APP_API_PREFIX;

client.defaults.baseURL = BACKEND_API_PREFIX;

const getAllCoupons = (id: string) => {
  let url = process.env.REACT_APP_COUPON ?? "";
  if (id) url = `${process.env.REACT_APP_COUPON}/${id}`;
  return client.get(url);
};

const createCoupon = (payload: ICreateCouponDto) => {
  return client.post(process.env.REACT_APP_COUPON ?? "", payload);
};

const updateCoupon = (id: string, payload: ICreateCouponDto) => {
  return client.put(`${process.env.REACT_APP_COUPON ?? ""}/${id}`, payload);
};

const deactivateCoupon = (id: string) => {
  return client.put(`${process.env.REACT_APP_COUPON_DEACTIVATE ?? ""}/${id}`);
};

export default {
  getAllCoupons,
  createCoupon,
  updateCoupon,
  deactivateCoupon,
};
