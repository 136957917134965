import React, { useState } from "react";
import {
  Modal,
  Button,
  TextField,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";

const ActivateBusinessModal = ({ open, onClose, onActivate, businessId }) => {
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);

  const handleActivate = async () => {
    try {
      setLoading(true);
      await onActivate(businessId, true, comments);
      onClose();
    } catch (error) {
      console.error("Error during activation:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Activate Business
        </Typography>
        <TextField
          label="Comments"
          fullWidth
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          multiline
          rows={4}
          variant="outlined"
          sx={{ mb: 2 }}
        />
        <Button
          variant="contained"
          onClick={handleActivate}
          disabled={loading || !comments}
          fullWidth
          sx={{
            backgroundColor: "#0bc10b",
            fontWeight: "bold",
            letterSpacing: "1px",
            "&:hover": { backgroundColor: "#079b07" },
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : (
            "Activate Business"
          )}
        </Button>
      </Box>
    </Modal>
  );
};

export default ActivateBusinessModal;
