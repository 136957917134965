import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/authProvider";

export const ProtectedRoute = () => {
  const { token } = useAuth();

  if (
    token.accessToken === null ||
    token.accessToken === undefined ||
    token.accessToken === "null"
  ) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
