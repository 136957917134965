import { useState } from "react";
import businessApi from "../api/business";

const useStorage = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);

  // Supported extensions for each media type
  const allowedExtensions = {
    "1": ["jpg", "jpeg", "png", "gif"], // Image extensions
    "2": ["mp4", "mov", "avi", "mkv"], // Video extensions
  };

  const handleFileUpload = async (
    file: File,
    mediaType: string,
    directoryName: string
  ) => {
    try {
      setUploading(true);
      setUploadError(null);

      // Extract file extension and validate it
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      if (
        !fileExtension ||
        !allowedExtensions[mediaType]?.includes(fileExtension)
      ) {
        const errorMessage = `Invalid file type. Allowed extensions for mediaType ${mediaType}: ${allowedExtensions[
          mediaType
        ].join(", ")}`;
        setUploadError(errorMessage);
        return { success: false, error: errorMessage };
      }

      // Prepare the form data for the API call
      const formData = new FormData();
      formData.append("file", file);
      formData.append("mediaType", mediaType);
      formData.append("directoryName", directoryName);

      // Call the uploadMediaFile API method
      const response = await businessApi.uploadMediaFile(formData);

      if (response.data.statusCode === 200) {
        setUploadedUrl(response.data.data[0].mediaUrl);
        return { success: true, url: response.data.data[0].mediaUrl };
      } else {
        const errorMessage = response.data?.message || "File upload failed";
        setUploadError(errorMessage);
        return { success: false, error: errorMessage };
      }
    } catch (error: any) {
      const errorMessage = error?.message || "File upload failed";
      setUploadError(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setUploading(false);
    }
  };

  return {
    uploading,
    uploadedUrl,
    uploadError,
    handleFileUpload,
  };
};

export default useStorage;
