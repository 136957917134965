import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs, { Dayjs } from "dayjs";

const OnGoingTab = ({
  handleSubmitPromotion,
  description,
}: {
  handleSubmitPromotion: Function;
  description: string;
}) => {
  const [isAllDay, setIsAllDay] = useState(false);
  const [fromTime, setFromTime] = useState<Dayjs | null>(null);
  const [toTime, setToTime] = useState<Dayjs | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<
    [Dayjs | null, Dayjs | null]
  >([null, null]);
  console.log(selectedDateRange);

  const [selectedOptions, setSelectedOptions] = useState({
    takeAway: false,
    dineIn: false,
    reservesDiscount: false,
  });

  const isButtonDisabled =
    !description || // Parent description is empty
    (!isAllDay && (!fromTime || !toTime)) || // Timing is incomplete
    !selectedDateRange[0] || // Start date is not selected
    !selectedDateRange[1]; // End date is not selected
  // Handlers
  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllDay(event.target.checked);
    if (event.target.checked) {
      setFromTime(dayjs().startOf("day")); // 12:00 AM
      setToTime(dayjs().endOf("day")); // 11:59 PM
    } else {
      setFromTime(null);
      setToTime(null);
    }
  };

  const handleOptionChange = (option: string) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [option]: !prev[option],
    }));
  };

  const handleFromTimeChange = (time: Dayjs | null) => {
    setFromTime(time);
  };

  const handleToTimeChange = (time: Dayjs | null) => {
    setToTime(time);
  };

  const handleSubmit = async () => {
    // Prepare the payload for Ongoing promotion
    const payload = {
      scheduleType: "ongoing",
      isTakeAway: selectedOptions.takeAway,
      isDineIn: selectedOptions.dineIn,
      isMiluim: selectedOptions.reservesDiscount,
      startDate: selectedDateRange[0]
        ? selectedDateRange[0].format("YYYY-MM-DD")
        : null,
      endDate: selectedDateRange[1]
        ? selectedDateRange[1].format("YYYY-MM-DD")
        : null,
      startTime: fromTime ? fromTime.format("hh:mm A") : null,
      endTime: toTime ? toTime.format("hh:mm A") : null,
    };

    // Call the shared handleSubmitPromotion function
    await handleSubmitPromotion(payload);
  };

  return (
    <Box
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: "8px",
        padding: "24px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        margin: "auto",
        backgroundColor: "#fff",
      }}
    >
      {/* Title */}
      <Typography variant="h5" color="error" sx={{ mb: 2, fontWeight: "bold" }}>
        OnGoing
      </Typography>

      {/* Checkboxes */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="error"
                checked={selectedOptions.takeAway}
                onChange={() => handleOptionChange("takeAway")}
              />
            }
            label="Take Away"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="error"
                checked={selectedOptions.dineIn}
                onChange={() => handleOptionChange("dineIn")}
              />
            }
            label="Dine In"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="error"
                checked={selectedOptions.reservesDiscount}
                onChange={() => handleOptionChange("reservesDiscount")}
              />
            }
            label="Reserves and Soldiers Discount"
          />
        </Grid>
      </Grid>

      {/* Date Range Picker */}
      <Box sx={{ mt: 5 }}>
        <StaticDateRangePicker
          value={selectedDateRange}
          onChange={(newValue) => setSelectedDateRange(newValue)}
          sx={{
            [`.MuiPickersLayout-contentWrapper`]: {
              alignItems: "center",
            },
            [`.MuiPickersToolbar-root`]: {
              display: "none", // Hide toolbar
            },
            [`.MuiDialogActions-root`]: {
              display: "none", // Hide footer buttons
            },
          }}
        />
      </Box>

      {/* All Day Checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            color="error"
            checked={isAllDay}
            onChange={handleAllDayChange}
          />
        }
        label="All Day (12:00 AM - 11:59 PM)"
      />

      {/* Time Selection */}
      {!isAllDay && (
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="From Time"
              value={fromTime}
              onChange={handleFromTimeChange}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TimePicker
              label="To Time"
              value={toTime}
              onChange={handleToTimeChange}
              sx={{ width: "100%" }}
            />
          </Grid>
        </Grid>
      )}

      {/* Submit Button */}
      <Button
        variant="contained"
        sx={{
          backgroundColor: "#FE0000",
          color: "#ffffff",
          width: "100%",
          fontWeight: "bold",
          "&:hover": { backgroundColor: "#bf0f1b" },
        }}
        disabled={isButtonDisabled}
        onClick={handleSubmit}
      >
        Advertise a Benefit
      </Button>
    </Box>
  );
};

export default OnGoingTab;
