import { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import debounce from "lodash/debounce";
import { UseFormSetValue } from "react-hook-form";
import useApi from "../../hooks/useApi";
import locationApi from "../../api/location";
import { IBusinessRegRequest } from "../../types/business";

interface AddressOption {
  place_id: string;
  formatted_address: string;
  description: string;
  city?: string;
}

interface Props {
  field: any;
  setValue: UseFormSetValue<IBusinessRegRequest>;
}

const GoogleAddressAutocomplete = ({ field, setValue }: Props) => {
  const [options, setOptions] = useState<AddressOption[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const searchLocationApi = useApi(locationApi.searchLocations);
  const placeDetailsApi = useApi(locationApi.getPlaceDetails);

  const fetchAddressPredictions = debounce(async (input: string) => {
    if (!input || input.length < 2) {
      setOptions([]);
      return;
    }

    setLoading(true);
    try {
      const response = await searchLocationApi.request(input);
      const predictions = response?.data ?? [];
      if (Array.isArray(predictions)) {
        setOptions(predictions);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Error fetching predictions:", error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handlePlaceSelect = async (option: AddressOption | null) => {
    if (!option) return;

    try {
      const response = await placeDetailsApi.request(option.place_id);
      const placeDetails = response?.data;

      field.onChange(placeDetails.formatted_address);

      const city = placeDetails.address_components?.find((component: any) =>
        component.types.includes("locality")
      )?.long_name;

      if (city) {
        setValue("city", city);
      }
    } catch (error) {
      console.error("Error fetching place details:", error);
    }
  };

  return (
    <Autocomplete
      freeSolo
      loading={loading}
      options={options || []}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description || ""
      }
      value={field.value || null}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        fetchAddressPredictions(newInputValue);
      }}
      onChange={(_, newValue) => {
        if (typeof newValue === "string") {
          field.onChange(newValue);
        } else if (newValue) {
          handlePlaceSelect(newValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Business Address (city or street)"
          variant="outlined"
          fullWidth
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              borderColor: "#E3E3E3",
              borderWidth: "2px",
              "& fieldset": {
                borderColor: "#E3E3E3",
                borderWidth: "3px",
              },
              "&:hover fieldset": {
                borderColor: "#E3E3E3",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#FE0000",
              },
            },
            "& .MuiInputLabel-root": {
              "&.Mui-focused": {
                color: "#FE0000",
              },
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <li {...props}>
          <div style={{ padding: "8px" }}>{option.description}</div>
        </li>
      )}
    />
  );
};

export default GoogleAddressAutocomplete;
