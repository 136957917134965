import client from "./client";
import {
  BusinessDetails,
  IActivateBusinessDto,
  IBusinessRegRequest,
  IDeactivateBusinessDto,
} from "../types/business";

const BACKEND_API_PREFIX = process.env.REACT_APP_API_PREFIX;

client.defaults.baseURL = BACKEND_API_PREFIX;

const uploadMediaFile = (formData) => {
  return client.post(
    `${process.env.REACT_APP_UPLOAD_MEDIA_FILE ?? ""}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const registerBusiness = (payload: IBusinessRegRequest) => {
  return client.post(process.env.REACT_APP_REGISTER_BUSINESS ?? "", payload);
};

const editBusiness = (id: string, payload: IBusinessRegRequest) => {
  return client.put(
    `${process.env.REACT_APP_ALL_BUSINESSES ?? ""}/${id}`,
    payload
  );
};

const getAllBusinesses = () => {
  return client.get(process.env.REACT_APP_ALL_BUSINESSES ?? "");
};

const getBusinessDetails = (id: string) => {
  return client.get(`${process.env.REACT_APP_ALL_BUSINESSES ?? ""}/${id}`);
};

const deactivateBusiness = (id: string, payload: IDeactivateBusinessDto) => {
  return client.put(
    `${process.env.REACT_APP_DEACTIVATE_BUSINESS ?? ""}/${id}`,
    payload
  );
};

const activateBusiness = (id: string, payload: IActivateBusinessDto) => {
  return client.put(
    `${process.env.REACT_APP_ACTIVATE_BUSINESS ?? ""}/${id}`,
    payload
  );
};

const checkActiveBusiness = (payload: BusinessDetails) => {
  return client.post(
    `${process.env.REACT_APP_BUSINESS_DETAILS ?? ""}/get-active-businesses`,
    payload
  );
};

const checkRemovedBusinessDetailsApi = (payload: BusinessDetails) => {
  return client.post(
    `${process.env.REACT_APP_BUSINESS_DETAILS ?? ""}/get-removed-businesses`,
    payload
  );
};

const checkBusinessJoinedByDate = (payload: BusinessDetails) => {
  return client.post(
    `${
      process.env.REACT_APP_BUSINESS_DETAILS ?? ""
    }/get-businesses-by-joining-date`,
    payload
  );
};

const checkAmountOfBenefitsRaised = (payload: BusinessDetails) => {
  return client.post(
    `${
      process.env.REACT_APP_BUSINESS_DETAILS ?? ""
    }/get-promotions-by-businesses`,
    payload
  );
};

export default {
  registerBusiness,
  editBusiness,
  getAllBusinesses,
  getBusinessDetails,
  deactivateBusiness,
  activateBusiness,
  checkActiveBusiness,
  checkRemovedBusinessDetailsApi,
  checkBusinessJoinedByDate,
  checkAmountOfBenefitsRaised,
  uploadMediaFile,
};
