import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useApi from "../hooks/useApi";
import promotionApi from "../api/promotion";
import { Box, CircularProgress, Grid } from "@mui/material";

const PictureFromDatabase = ({
  setSelectedImageUrl,
}: {
  setSelectedImageUrl: Function;
}) => {
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const getPicturesApi = useApi(promotionApi.getPicturesFromDatabase);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    getPicturesApi.request(page);
  };

  useEffect(() => {
    try {
      const newData = getPicturesApi?.data;
      if (newData) {
        setData((prevData) => [...prevData, ...newData?.data]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [getPicturesApi?.data]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      getPicturesApi.loading
    ) {
      return;
    }
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleImageClick = (imageObj) => {
    setSelectedImage(imageObj);
    setSelectedImageUrl(imageObj.imageUrl);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{
          maxWidth: "100%",
          padding: 2,
        }}
      >
        {data.map((image, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={image.imageId}>
            <LazyLoadImage
              alt=""
              src={image.mediaUrl}
              width="100%"
              height="auto"
              onClick={() =>
                handleImageClick({ imageUrl: image.mediaUrl, index: index })
              }
              className={selectedImage?.index === index ? "selected" : ""}
              style={{ borderRadius: "8px", cursor: "pointer" }}
            />
          </Grid>
        ))}
      </Grid>

      {getPicturesApi.loading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            zIndex: 10,
          }}
        >
          <CircularProgress sx={{ color: "#FE0000" }} />
        </Box>
      )}
    </Box>
  );
};

export default PictureFromDatabase;
